<template>
    <div id="modalIdentification" class="row">
        <b-modal id="modalIdentification"
                 ref="modalIdentification"
                 centered
                 size="lg"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="modalShow"
                 :title="$t('titleModalIdentification')">
            <div class="food-custom-modal modal-identification">

                <div id="chooseLang" class="float-right">
                    <span class="">{{$t('currentLang')}} <small id="langPopover" class="changeLangButton">({{$t('changeLang')}})</small></span>
                </div>
                <b-popover :show.sync="showLangPopover" target="langPopover" placement="bottom" triggers="click focus">
                    <ul class="changeLangList">
                        <template  v-for="(lang, key , index) in this.langs">
                            <li @click="changeLang(key)" :key=key+0>{{lang}}</li>
                            <b-dropdown-divider :key=key+1 v-if="index !== Object.keys(langs).length - 1"></b-dropdown-divider>
                        </template>
                    </ul>
                </b-popover>

                <p class="identification-explanation" v-html="$t('modalIdentificationExplanation')"></p>
                <b-form-group id="labelFirstname"
                            :label="$t('firstname') + ' : '"
                            label-for="inputFirstname">
                <b-form-input id="inputFirstname"
                                type="text"
                                class="custom-input"
                                v-model="firstname"
                                required>
                </b-form-input>
                </b-form-group>

                <b-form-group id="labelLastname"
                            :label="$t('lastname') + ' : '"
                            label-for="inputLastname">
                    <b-form-input id="inputLastname"
                                type="text"
                                class="custom-input"
                                v-model="lastname"
                                required>
                    </b-form-input>
                </b-form-group>
                <div class="delete-modal-actions">
                    <span class="btn ok" @click="handleOk">OK</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    var customer = require("@/assets/js/Customer")
    export default {
        name: "Modal",
        data () {
            return {
                modalShow: true,
                modalTitle: this.$t('titleModalIdentification'),
                lastname: "",
                firstname: "",
                showLangPopover: false,
                lang: "en",
            }
        },
        methods: {
            handleOk (evt) {
                evt.preventDefault()
                if (!this.lastname || !this.firstname) {
                    alert(this.$t('errorModalIdentification'))
                } else {
					this.$parent.showLoader = true;
                    customer.registerCustomer(this.firstname, this.lastname, () => {
                        if(isNotNull(localStorage.getItem("DKC_complexId"))){
                            this.$parent.setBase();
                            this.$refs.modalIdentification.hide();
                            localStorage.setItem("DKC_lang", this.lang);
                            window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + "/scan");
                        }else{
                            alert(this.$t('errorRegisterModalIdentification'))
                        }
                        this.$parent.showLoader = false;
                    })
                }
            },
            changeLang(newLang){
                if(!Object.keys(this.langs).includes(newLang))
                    newLang = "en";

                this.$root.$i18n.locale = newLang;
                this.lang = newLang;
                this.showLangPopover = false;
            }
        }
    }
</script>