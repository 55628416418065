<template>
    <div id="modalCabin" class="row">
        <b-modal id="modalCabin"
                 ref="modalCabin"
                 centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close
                 header-text-variant="light"
                 v-model="modalShow"
                 :title="title">

            <b-form-group>
                <b-list-group-item button v-for="option in options" :key="option.value" @click="selectRoom(option.value)">
                    <img src="@/assets/img/picto_room.png" class="pictoRoom"/>
                    {{option.text}}
                </b-list-group-item>
                <b-form-invalid-feedback :state="state">{{$t('errorChooseCabin')}}</b-form-invalid-feedback>
            </b-form-group>

            <div slot="modal-footer" class="modal-footer-custom">
                <b-button class="float-right" variant="success" @click="handleOk">OK</b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import BImg from "bootstrap-vue/src/components/image/img";
    var MQTT = require("@/assets/js/MqttService");
    export default {
        name: "ModalCabin",
        components: {BImg},
        data () {
            return {
                modalShow: true,
                title: this.$t('chooseCabinTitle'),
                selected: "",
                options: [
                    { text: this.$t('cabin') + " 1", value: "1" },
                    { text: this.$t('cabin') + " 2", value: "2" },
                    { text: this.$t('cabin') + " 3", value: "3" }
                ],
                state: ""
            }
        },
        methods: {
            handleOk () {
                if(this.selected != ""){
                    this.modalShow = false;
                    this.$parent.showLoader = true;
                    var self = this;
                    MQTT.publishMessage("SCAN_ROOM", "", function(){
                        self.$parent.showLoader = false;
                        self.$router.push(localStorage.getItem("DKC_base") + "/scan");
                    });
                }else{
                    this.state = false;
                }
            },
            selectRoom(number){
                this.selected = number;
            }
        }
    }
</script>

<style scoped>

</style>