<template>
    <div id="food" class="univers">
        <NewUpdateAvailable v-if="newUpdateAvailable" /> 
        <Header :nbProductsCart="nbProductsCart" v-if="$route.name != 'FoodPaymentTicket' && $route.name != 'FoodPaymentDone'" :totalAmount="basket.totalAmount || 0" :isOffline="isOffline"></Header>
        <router-view :key="$route.fullPath" :isOffline="isOffline"></router-view>
        <ModalIdentification v-if="modalShow"></ModalIdentification>
        <Loader :content="$t('loading')" v-if="showLoader"></Loader>
        <b-modal id="shareModal" ref="shareModal" hide-footer centered :title="$t('sharePopinTitle')">
            <div class="modal-body">
                <img class="bigQrCode" :src="qrCodeImg"/>
            </div>
        </b-modal>
        <ModalCrossSelling :productCrossSell="productCrossSell"></ModalCrossSelling>
        <ModalCabin v-if="modalCabinShow"></ModalCabin>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
    </div>
</template>

<script>
    import "@/assets/css/custom-food.css"
    import "@/assets/utils/addtohomescreen.css"
    import "@/assets/utils/addtohomescreen.js"
    import Header from "@/components/Food/Header";
    import ModalIdentification from "@/components/Food/ModalIdentification";
    import ModalStore from "@/components/Food/ModalStore";
    import Loader from "@/components/Common/Loader";
    import ModalCrossSelling from "@/components/Food/ModalCrossSelling";
    import Alert from "@/components/Common/Alert";
    import NewUpdateAvailable from "@/components/Food/NewUpdateAvailable";
    import {isNotNull, getConfig} from "@/assets/js/Utils"
    import ModalCabin from "@/components/Food/ModalCabin";
    var Basket = require("@/assets/js/Food/Basket");
    var qrcodeGenerator = require('qrcode-generator')
    var App = require("@/assets/js/App")
    var Stats = require("@/assets/js/Stats")
    var Utils = require("@/assets/js/Utils")
    var MQTT = require("@/assets/js/Food/MqttService")
    var Customer = require("@/assets/js/Customer")
    let idb = require("idb")

    export default {
        name: "Food",
        components: {ModalCabin, Header,ModalIdentification,Loader,ModalCrossSelling,Alert, ModalStore, NewUpdateAvailable},
        data: function() {
            return{
                basket: {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                nbProductsCart : 0,
                qrCodeImg :'',
                showLoader: false,
                modalShow: false,
                base: "",
                productCrossSell: null,
                alertContent: "",
                alertVariant: "",
                logoURL: "",
                customer: null,
                modalCabinShow: false,
                isOffline: sessionStorage.getItem("DKC_is_offline") == "true",
                keyword: null,
                showModalStore: false,
                newUpdateAvailable: false
            }
        },
        created(){
            let i18n = {};
            Object.keys(this.langs).forEach( lang => {
                    i18n[lang] = require('@/locales/'+lang+'.json');
                    this.$root.$i18n.setLocaleMessage(lang,i18n[lang].food)
                }
            );

            if(isNotNull(localStorage.getItem("DKC_lang"))){
                this.$root.$i18n.locale = localStorage.getItem("DKC_lang");
            }else{
                this.$root.$i18n.locale = 'en';
            }

            if(isNotNull(localStorage.getItem("DKC_complexId")) && isNotNull(localStorage.getItem("DKC_demoId"))){
                Utils.checkToken();
            }

            var token = localStorage.getItem("DKC_food_token");
            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                Utils.clearCacheExceptCustomer();
                if(isNotNull(token)){
                    localStorage.setItem("DKC_needPublishPoster", "true");
                }
            }
            this.getAllProducts()
            App.saveDeviceType();
            App.checkParameters(window.location.pathname, this);
            this.setBase();

            if(!isNotNull(window.location.href.indexOf("needParameters")) && !isNotNull(window.location.href.indexOf("ticketId")) && !isNotNull(token)){
                if(this.$route.name != 'FoodIndex'){
                    this.$router.push(this.base+ "/scan");
                }else{
                    this.modalShow = true;
                }
            }else{
                this.keyword = sessionStorage.getItem("DKC_food_keyword") || null;
                if(this.keyword == null){
                    Basket.getKeyword(this.$i18n.locale, (res) => {
                        this.keyword = res;
                        sessionStorage.setItem("DKC_food_keyword", this.keyword) 
                        console.log(res)
                    })
                }
                if(!isNotNull(window.location.href.indexOf("ticketId"))){
                    
                document.addEventListener("demoFetchedEvent", e =>{
                    this.showModalStore = localStorage.getItem("DKC_food_store_selected") !== "true"
                }, false)
                }
            }
            this.refreshCustomer();
            document.addEventListener('newContentAvailable', (e) => {
                this.newUpdateAvailable = true;
            }, false);
            this.basket = Basket.getBasket();

            if(isNotNull(localStorage.getItem("DKC_productCrossSell"))){
                this.productCrossSell = JSON.parse(localStorage.getItem("DKC_productCrossSell"));
            }

            window.onunload = function(){
                this.closeHandler();
            }
        },
        mounted(){
            Utils.setAppContext(this);
            MQTT.susbscribeReset(this);       

            window.addEventListener("offline", envent => {
                this.isOffline = true
                sessionStorage.setItem("DKC_is_offline", true);
            })

            window.addEventListener("online", envent => {
                this.isOffline = false
                sessionStorage.setItem("DKC_is_offline", false)
            })

            var needReferesh = localStorage.getItem("DKC_needRefresh");
            var needPublishPoster = localStorage.getItem("DKC_needPublishPoster");

            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                needReferesh = "true";
            }

            if(!needReferesh){
                this.setStats();
            }

            if(isNotNull(needPublishPoster) && needPublishPoster == "true"){
                MQTT.publishMessage("SCAN_POSTER", "", () => {
                    if(isNotNull(needReferesh) && needReferesh == "true"){
                        needReferesh = "false";
                        window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
                    }
                });
            }else if(isNotNull(needReferesh) && needReferesh == "true"){
                window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
            }
            this.basket = Basket.getBasket();
            localStorage.removeItem("DKC_needPublishPoster");
            localStorage.removeItem("DKC_needRefresh");
        },
        beforeUpdate(){
            MQTT.unsusbscribeReset();
        },
        updated(){
            MQTT.susbscribeReset(this);
            if(!isNotNull(this.customer)){
                var token = localStorage.getItem("DKC_food_token");
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            }
        },
        methods: {
            basketChange: function(){
                this.basket = Basket.getBasket();
                this.count(this.basket.products);
            },
            count: function(products) {
                var count = 0;
                products.forEach(function(product) {
                    count+=product.qty;
                });
                this.nbProductsCart = count;
            },
            closeHandler:function() {
                this.basket = Basket.getBasket();
                if(isNotNull(localStorage.getItem("DKC_food_token"))){
                   /* Basket.updateBasket(function(newBasket) {
                        let tmpProducts = self.basket.products;
                        newBasket.products.forEach(function(element) {
                            tmpProducts.some(function(oldProduct, id) {
                                if(element.product.ean == oldProduct.product.ean && oldProduct.qtyLocked && oldProduct.qty == element.qty){
                                    element.qtyLocked = oldProduct.qtyLocked;
                                    tmpProducts.splice(id, 1)
                                    return true;
                                }
                            })
                        })*/
                        //newBasket.lockedProducts = self.basket.lockedProducts;
                        //localStorage.setItem("DKC_basket", JSON.stringify(self.basket));
                    //})
                }
            },
            share: function(){
                var qr = qrcodeGenerator(0, 'L');
                qr.addData(window.location.origin + localStorage.getItem("DKC_base"));
                qr.make();
                this.qrCodeImg = qr.createDataURL(10, 0);
                this.$refs.shareModal.show();
            },
            setBase: function(){
                var referer = "DKC";
                var univers = localStorage.getItem("DKC_univers");
                var demoId = localStorage.getItem("DKC_demoId");
                var scanType = localStorage.getItem("DKC_shortScanType");
                var complexIdParent = localStorage.getItem("DKC_complexId");

                if(isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType)){
                    if(isNotNull(complexIdParent)){
                        this.base = "/food/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType + "_" + complexIdParent;
                    }else{
                        this.base = "/food/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType;
                    }
                }
                localStorage.setItem("DKC_base", this.base);
            },
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            },
            checkDemonstrationLogo(){
                var path = Utils.getConfig().PATH_IMG + localStorage.getItem("DKC_demoId");
                this.logoURL = Utils.getConfig().DKW_URL + path;
            },
            refreshCustomer(){
                var self = this;
                var token = localStorage.getItem("DKC_food_token");
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            },
            setStats(){
                var complexId = localStorage.getItem("DKC_complexId");
                if(isNotNull(complexId) && window.location.href.indexOf("needParameters") == -1 && this.$route.name == "FoodIndex"){
                    var complexIdParent = localStorage.getItem("DKC_complexIdParent");
                    var deviceType = localStorage.getItem("DKC_deviceType");
                    var parameters = {
                        nbPlayedDemos: 1
                    }

                    if(isNotNull(complexIdParent) && isNotNull(complexId) && complexIdParent != complexId)
                        parameters.complexIdParent = complexIdParent;

                    if(isNotNull(deviceType))
                        parameters.deviceType = deviceType;

                    Stats.setStats(parameters);
                }
            },
            getAllProducts(){
                var path = "/catalog/product/getAll";
                var url = getConfig().DKW_URL + path;
                var request = new XMLHttpRequest();
                request.onreadystatechange = () => {
                    if (request.readyState === XMLHttpRequest.DONE) {
                        if (request.status === 200 && request.response != "") {
                            this.saveAllProductsLocaly(JSON.parse(request.response));
                        }else{
                            getConfig().appContext.showAlert(getConfig().appContext.$t('errorGetCustomer'),"error");
                        }
                    }
                };
                request.open('GET', url, true);
                request.send();
            },
            saveAllProductsLocaly(products){
                if (!('indexedDB' in window)) {return null;}
                const db = idb.openDB('demokit-food', 1, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains('products')) {
                            db.createObjectStore('products', {keyPath: 'id'});
                        }
                    }
                })
                .then(db => {
                    const tx = db.transaction('products', 'readwrite');
                    Promise.all(products.map(product => tx.store.put(product)))  
                    return tx.complete
                })
        },
    },
    watch:{
            basket: function(newVal, oldVal) {
                //todo ?
                localStorage.getItem("DKC_basket");
                this.count(this.basket.products);
                this.$emit('chgtQty', this.nbrProducts);
                console.log(newVal)
            },
            '$route': {
                handler: function() {
                    this.closeHandler();
                },
                deep: true,
                immediate: true
            }
        }
    }

</script>