<template>
    <div id="modalCrossSelling" class="row">
        <b-modal id="modalCrossSelling"
                 ref="modalCrossSelling"
                 centered
                 :size="modalSize"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close
                 header-text-variant="light"
                 header-class="modal-header-custom"
                 v-model="modalShow"
                 :title="modalTitle">

            
            <h5>{{modalContent}}</h5>

            <br/>

            <ProductBloc v-if="productCrossSell != null && crossSellAccepted == false" :isCrossSell="true" :scannedProduct="productCrossSell"></ProductBloc>

            <div slot="modal-footer" class="modal-footer-custom">
                <b-button class="float-left btn-danger" @click="handleNo" v-if="crossSellAccepted == false">{{modalNo}}</b-button>
                <b-button class="float-right" variant="success" @click="handleOk">{{modalYes}}</b-button>
            </div>


        </b-modal>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import ProductBloc from "@/components/Common/ProductBloc";
    var MQTT = require("@/assets/js/MqttService");

    export default {
        name: "ModalCrossSelling",
        inherit: true,
        components: {ProductBloc},
        props: {
            "productCrossSell": Object
        },
        data () {
            return {
                modalShow: false,
                modalTitle: this.$t('modalCrossSellTitle'),
                modalContent: this.$t('modalCrossSellMessage'),
                modalNo: this.$t('crossSellingNoButton'),
                modalYes: this.$t('crossSellingYesButton'),
                crossSellAccepted : false,
                modalSize: "lg"
            }
        },
        mounted(){
            let fromCrossSell= localStorage.getItem("DKC_fromCrossSell") == "true";
            if(isNotNull(this.productCrossSell) && !fromCrossSell){
                var self = this;
                setTimeout(function(){
                    self.modalShow = true;
                }, 2000);
            }else{
                this.modalShow = false;
            }
    },
        methods: {
            handleOk () {
                this.modalShow = false;
                localStorage.setItem("DKC_productCrossSell", JSON.stringify(this.productCrossSell));
                this.$parent.showLoader = true;
                var self = this;
                localStorage.setItem("DKC_fromCrossSell", true)
                MQTT.publishMessage("POST_CROSS_SELL", "",  function(){
                    self.$parent.showLoader = false;
                    self.$parent.modalCabinShow = true;
                });
            },
            handleNo(){
                /*
                this.modalShow = false;
                this.$parent.productCrossSell = null;
                */
            }
        },
        watch: {
            productCrossSell : function(){
                if(isNotNull(this.productCrossSell)){
                    var self = this;
                    setTimeout(function(){
                        self.modalShow = true;
                    }, 5000);
                }else{
                    this.modalShow = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>