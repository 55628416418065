<template>
    <div id="modalIdentification" class="row">
        <b-modal id="modalIdentification"
                 ref="modalIdentification"
                 centered
                 size="sm"
                 header-text-variant="light"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close
                 footer-class="modal-footer-custom"
                 hide-header
                 v-model="modalShow">

            <p class="store-explanation">{{$t("storeModalWelcomeText", {storeName, brandName: brandName.toUpperCase()})}}</p>

            <template slot="modal-footer">
                    <b-button class="float-right btn-active" @click="handleOk">{{$t("storeModalBegin")}}</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    var customer = require("@/assets/js/Customer")
    export default {
        name: "Modal",
        data () {
            return {
                modalShow: true,
                modalTitle: this.$t('titleModalIdentification'),
                storeName: localStorage.getItem("DKC_food_city") || "Seclin",
                brandName: localStorage.getItem("DKC_food_brand") || this.$t("yourStore"),
            }
        },
        methods: {
            handleOk (evt) {
                localStorage.setItem("DKC_food_store_selected", true)
                this.$emit("hide")
            }
        }
    }
</script>