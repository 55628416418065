<template>
    <div id="conteneur">
        <div id="loader">
            <div class="loader-block">

                <spring-spinner
                        id="spinner"
                        :animation-duration="3000"
                        :size="50"
                        :color="'#3498db'"
                />

                <p>{{content}}</p>
            </div>
        </div>
    </div>

</template>

<script>
    import { SpringSpinner } from 'epic-spinners';
    export default {
        name: "Loader",
        components: {
            SpringSpinner
        },
        props: {
            "content": String
        }
    }
</script>