<template>
    <div class="app-header">
        <header class="food-header" v-if="!$route.query.needParameters">
            
            <div @click="showSidebar = true" :style="{backgroundImage: `url('${$parent.logoURL}')`}" class="brand-logo"></div>
            <div class="header-basket" @click="$router.push(`${base}/basket`)">
                <template v-if="!showStep">
                    <div class="icon-wrapper">
                        <span class="icon-panier"></span>
                        <span :class="{'nbr-products': true, 'shake': animateCart}">{{nbProductsCart}}</span>
                    </div>
                    <span class="total-amount">{{fromattedTotalAmount}}€</span>
                </template>
                <template v-else>
                    <span class="header-step">{{stepToShow}}</span>
                </template>
            </div>
        </header>
        <div v-if="showSidebar" class="sidebar-wrapper" @click.self="showSidebar = false">
            <div class="sidebar py-2">
                <div class="sidebar-header" @click="showSidebar = false">
                    <span @click="showSidebar = true" class="icon-fleche-gauche"></span>
                    <div class="sidebar-logo" :style="{backgroundImage: `url('${$parent.logoURL}')`}"></div>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div @click="expandHours = !expandHours" class="sidebar-content-header">
                        <span class="icon-magasin"></span>
                        <span class="content-title">{{$t("storeInfo")}}</span>
                        <span :class="{'icon-fleche-up': expandHours, 'icon-fleche-down' : !expandHours}"></span>
                    </div>
                    <b-collapse id="expand-hours" v-model="expandHours" class="mt-2">
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("mondayToSaturday")}}</span>
                            <span class="hours">08:00am - 08:00pm</span>
                        </div>
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("sunday")}}</span>
                            <span class="hours">09:00am - 08:00pm</span>
                        </div>
                    </b-collapse>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header">
                        <span class="icon-aide"></span>
                        <span class="content-title">{{$t("needHelp")}}</span>
                    </div>
                </div>
                <div class="account-button">
                    <span class="icon-profil"></span>
                    <span>{{$t("accountButtonLabel")}}</span>
                </div>
            </div>
        </div>
        <nav class="header-menu">
            <b-collapse id="collapse-4">
                <div @click="goSchedule()" class="menu-element">   
                    <i class="mdi mdi-store elment-icon"></i>
                    <i class="elment-text">{{$t("storeOpeningHours")}}</i>
                </div>
            </b-collapse>
            <b-collapse id="collapse-offline">
                <div class="offline-bar">
                    {{$t("offlineText")}}
                </div>
            </b-collapse>
        </nav>
    </div>
</template>

<script>
    export default {
        name : 'Header',
        props : ['nbProductsCart', "isOffline", "totalAmount"],
        data(){
            return{
                base: localStorage.getItem("DKC_base"),
                showMenu: false,
                offlineBar: true,
                animateCart: false,
                showSidebar: false,
                expandHours: true,
            }
        },
        methods: {
            redirect: function(){
                this.$parent.closeHandler();
                this.$router.push(localStorage.getItem("DKC_base"));
            },
            goBack(){
                if(this.$route.name === "FoodPaymentDone" || this.$route.name === "FoodPaymentTicket" ){
                    this.$router.push({name: "FoodIndex"})
                }else{
                    this.$router.go(-1)
                }
            },
            goSchedule(){
                this.$router.push(localStorage.getItem("DKC_base") + '/schedule');
            }
        },
        created(){
            this.$parent.checkDemonstrationLogo();
        },
        computed:{
            iconToShow(){
                if(this.$route.name === "FoodIndex" || this.$route.name === "FoodScan"){
                    return this.showMenu === true ? 'close' : 'menu'
                }
                return 'chevron'
            }, 
            fromattedTotalAmount(){
                return this.totalAmount.toFixed(2)
            },
            showStep(){
                return (this.$route.name === "FoodPaymentMethods" || this.$route.name === "FoodBasket")
            },
            stepToShow(){
                switch(this.$route.name){
                    case "FoodBasket": 
                        return this.$t("basketStep")
                    case "FoodPaymentMethods": 
                        return this.$t("paymentStep")
                    
                }
            },
            enableOfflineBar(){
                return (this.$route.name === "FoodScan" || this.$route.name === "FoodIndex") && this.isOffline
            }
        },
        watch:{
            nbProductsCart: function() {
                this.animateCart = true 
                setTimeout(() => this.animateCart = false, 1000)
            },
            enableOfflineBar(){
                this.$root.$emit('bv::toggle::collapse', 'collapse-offline')
            }
        }
    }
</script>